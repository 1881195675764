import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`matt`, `deslauriers`, `creative`, `code`, `generative`, `art`]} />
    <div>Details to come.</div>
  </Layout>
);

export default IndexPage
